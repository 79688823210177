<template>
  <div :class="$style.wrapper">
    <NuxtPage />
    <ModalContainer :class="$style.modalContainer">
      <template #overlay>
        <div :class="$style.overlay"></div>
      </template>
    </ModalContainer>
  </div>
</template>

<script setup lang="ts">
import "vue-haystack/style.css";
import "./styles/bootstrap.scss";
import "./styles/elements.scss";
import "./styles/fonts.scss";

import { ModalContainer } from "vue-haystack";

import { useHead } from "#imports";

useHead({ htmlAttrs: { lang: "de" }, link: [{ rel: "icon", type: "image/png", href: "/favicon.png" }] });
</script>

<style lang="scss">
.page-enter-active,
.page-leave-active {
  transition: all 0.2s;
}
.page-enter-from,
.page-leave-to {
  opacity: 0;
  filter: blur(1rem);
}
</style>

<style lang="scss" module>
.wrapper {
  width: 100%;
}

.modalContainer {
  z-index: 300;
}
.overlay {
  background-color: rgba(0, 0, 0, 0.8);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
</style>
