import revive_payload_client_qsEfFDeXxB from "/builds/leif/pulls-website/frontend/common/temp/node_modules/.pnpm/nuxt@3.10.2_@types+node@20.11.19_eslint@8.56.0_sass@1.71.0_typescript@5.3.3_vite@5.1.3_vue-tsc@1.8.27/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_N2ahyzvg3S from "/builds/leif/pulls-website/frontend/common/temp/node_modules/.pnpm/nuxt@3.10.2_@types+node@20.11.19_eslint@8.56.0_sass@1.71.0_typescript@5.3.3_vite@5.1.3_vue-tsc@1.8.27/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_IbCcBS9C7m from "/builds/leif/pulls-website/frontend/common/temp/node_modules/.pnpm/nuxt@3.10.2_@types+node@20.11.19_eslint@8.56.0_sass@1.71.0_typescript@5.3.3_vite@5.1.3_vue-tsc@1.8.27/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_Pr2G9McOHr from "/builds/leif/pulls-website/frontend/common/temp/node_modules/.pnpm/nuxt-site-config@2.2.9_@nuxt+devtools@1.0.8_@vue+compiler-core@3.4.19_nuxt@3.10.2_postcss@8.4_yeefsuir2btef7ibxcw5akuw4q/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.mjs";
import payload_client_xDrYGEWQOn from "/builds/leif/pulls-website/frontend/common/temp/node_modules/.pnpm/nuxt@3.10.2_@types+node@20.11.19_eslint@8.56.0_sass@1.71.0_typescript@5.3.3_vite@5.1.3_vue-tsc@1.8.27/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_GQZamYfkCy from "/builds/leif/pulls-website/frontend/common/temp/node_modules/.pnpm/nuxt@3.10.2_@types+node@20.11.19_eslint@8.56.0_sass@1.71.0_typescript@5.3.3_vite@5.1.3_vue-tsc@1.8.27/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import components_plugin_KR1HBZs4kY from "/builds/leif/pulls-website/frontend/apps/frontend/.nuxt/components.plugin.mjs";
import prefetch_client_0ISFYDZ3mZ from "/builds/leif/pulls-website/frontend/common/temp/node_modules/.pnpm/nuxt@3.10.2_@types+node@20.11.19_eslint@8.56.0_sass@1.71.0_typescript@5.3.3_vite@5.1.3_vue-tsc@1.8.27/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_Xj2vq1bQsv from "/builds/leif/pulls-website/frontend/common/temp/node_modules/.pnpm/nuxt-gtag@1.2.0/node_modules/nuxt-gtag/dist/runtime/plugin.client.mjs";
import plugin_ixT5ynxmJ0 from "/builds/leif/pulls-website/frontend/common/temp/node_modules/.pnpm/@nuxtjs+apollo@5.0.0-alpha.11_react-dom@18.2.0_react@18.2.0_typescript@5.3.3_vue@3.4.19/node_modules/@nuxtjs/apollo/dist/runtime/plugin.mjs";
import chunk_reload_client_Ig8LU8Jlzq from "/builds/leif/pulls-website/frontend/common/temp/node_modules/.pnpm/nuxt@3.10.2_@types+node@20.11.19_eslint@8.56.0_sass@1.71.0_typescript@5.3.3_vite@5.1.3_vue-tsc@1.8.27/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import apolloError_K5jGQskO4f from "/builds/leif/pulls-website/frontend/apps/frontend/plugins/apolloError.ts";
export default [
  revive_payload_client_qsEfFDeXxB,
  unhead_N2ahyzvg3S,
  router_IbCcBS9C7m,
  _0_siteConfig_Pr2G9McOHr,
  payload_client_xDrYGEWQOn,
  check_outdated_build_client_GQZamYfkCy,
  components_plugin_KR1HBZs4kY,
  prefetch_client_0ISFYDZ3mZ,
  plugin_client_Xj2vq1bQsv,
  plugin_ixT5ynxmJ0,
  chunk_reload_client_Ig8LU8Jlzq,
  apolloError_K5jGQskO4f
]