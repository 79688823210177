import { default as _91_46_46_46slug_93CIpWomzN8bMeta } from "/builds/leif/pulls-website/frontend/apps/frontend/pages/[...slug].vue?macro=true";
import { default as devJ1u4R7WV4rMeta } from "/builds/leif/pulls-website/frontend/apps/frontend/pages/dev.vue?macro=true";
export default [
  {
    name: _91_46_46_46slug_93CIpWomzN8bMeta?.name ?? "slug",
    path: _91_46_46_46slug_93CIpWomzN8bMeta?.path ?? "/:slug(.*)*",
    meta: _91_46_46_46slug_93CIpWomzN8bMeta || {},
    alias: _91_46_46_46slug_93CIpWomzN8bMeta?.alias || [],
    redirect: _91_46_46_46slug_93CIpWomzN8bMeta?.redirect,
    component: () => import("/builds/leif/pulls-website/frontend/apps/frontend/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: devJ1u4R7WV4rMeta?.name ?? "dev",
    path: devJ1u4R7WV4rMeta?.path ?? "/dev",
    meta: devJ1u4R7WV4rMeta || {},
    alias: devJ1u4R7WV4rMeta?.alias || [],
    redirect: devJ1u4R7WV4rMeta?.redirect,
    component: () => import("/builds/leif/pulls-website/frontend/apps/frontend/pages/dev.vue").then(m => m.default || m)
  }
]